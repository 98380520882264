<script lang="ts" setup>
const props = defineProps({
  currentIndex: {
    type: Number,
    default: 0
  },
  totalIndex: {
    type: Number,
    default: 4
  }
});
const emit = defineEmits(['update:change']);

const handleChange = (index: number) => {
  emit('update:change', index);
};

const distance = ref();
const animate = ref(true);
const progressBarStyle = computed(() => {
  const width = animate.value ? (100 / props.totalIndex) * (props.currentIndex + 1) + '%' : '0%';

  return {
    width: width,
    transition: animate.value ? 'width 1s cubic-bezier(0.87, 0, 0.13, 1)' : 'none'
  };
});
watch(
  () => props.currentIndex,
  (newValue, oldValue) => {
    distance.value = ((newValue + 1) / props.totalIndex) * 100;
    if (newValue < oldValue) {
      animate.value = false;
      setTimeout(() => {
        animate.value = true;
      }, 500);
    }
  }
);
</script>

<template>
  <div class="pagination-box">
    <div class="pagination-bar" :style="progressBarStyle"></div>
    <ul class="pagination">
      <li v-for="(item, index) in props.totalIndex" :key="index" :class="{ active: index === props.currentIndex }" @click="handleChange(index)">
        <div class="pagination__item"></div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.pagination-box {
  position: relative;
  overflow: hidden;
}

.pagination-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: toRem(4);
  background-color: $color-gray-2;
  pointer-events: none;
  transition: width 1s cubic-bezier(0.87, 0, 0.13, 1);
}

.pagination {
  display: flex;
  width: 100%;
  border-bottom: toRem(1) solid #cbc5bc;

  li {
    cursor: pointer;
    flex: 1;

    &:hover,
    &.active {
      .pagination {
        &__item {
          &::after {
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    width: 100%;
    height: toRem(4);
    background-color: transparent;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transform: translateX(-100%);
    }
  }
}

.white1 {
  .pagination {
    border-bottom-color: $color-white-1;
  }
  .pagination-bar {
    background-color: $color-white-1;
  }
}

.blue3 {
  .pagination {
    border-bottom-color: $blue3;
  }
  .pagination-bar {
    background-color: $blue3;
  }
}
</style>
